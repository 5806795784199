import React from 'react';
import { Link } from 'react-router-dom';
import Offcanvas from 'react-bootstrap/Offcanvas'
import {FaPhone, FaFax, FaFacebookF, FaTwitter, FaPinterestP, FaLinkedin, FaInstagram, FaVimeoV, FaDribbble, FaBehance, FaEnvelopeOpen, FaGithub, FaVoicemail } from "react-icons/fa";


const OffcanvasMenu = ({offcanvasShow, offcanvasHide}) => {
    return (
        <Offcanvas show={offcanvasShow} onHide={offcanvasHide} placement="end" className="header-offcanvasmenu">
            <Offcanvas.Header closeButton></Offcanvas.Header>
            <Offcanvas.Body>
                <form action="#" className="side-nav-search-form">
                    <div className="form-group">
                        <input type="text" className="search-field" name="search-field" placeholder="Search..." />
                        <button className="side-nav-search-btn"><i className="fas fa-search"></i></button>
                    </div>
                </form>
                <div className="row ">
                    <div className="col-lg-5 col-xl-6">
                        <ul className="main-navigation list-unstyled">
                            {/* <li><Link to={process.env.PUBLIC_URL + "/digital-agency"}>Digital Agency</Link></li>
                            <li><Link to={process.env.PUBLIC_URL + "/creative-agency"}>Creative Agency</Link></li>
                            <li><Link to={process.env.PUBLIC_URL + "/personal-portfolio"}>Personal Portfolio</Link></li>
                            <li><Link to={process.env.PUBLIC_URL + "/home-startup"}>Home Startup</Link></li>
                            <li><Link to={process.env.PUBLIC_URL + "/corporate-agency"}>Corporate Agency</Link></li> */}
                        </ul>
                    </div>
                    <div className="col-lg-7 col-xl-6">
                        <div className="contact-info-wrap">
                            <div className="contact-inner">
                                <address className="address">
                                    <span className="title">Contact Information</span>
                                    <p>Yorkshire <br /> United Kingdom</p>
                                </address>
                                <address className="address">
                                    <span className="title">We're Available 9am - 5pm. Mon-Fri.</span>
                                    <a href="mailto:upport@simamdigital.com" className="tel"><FaVoicemail /> support@simamdigital.com</a>
                                    {/* <a href="tel:12125553333" className="tel"><FaFax /> (121) 255-53333</a> */}
                                </address>
                            </div>
                            <div className="contact-inner">
                                <h5 className="title">Find us here</h5>
                                <div className="contact-social-share">
                                    <ul className="social-share list-unstyled">
                                    <li><a href="https://www.facebook.com/people/Simam-Digital/100086666457771/"><FaFacebookF /></a></li>
                                    <li><a hrefef="https://twitter.com/DigitalSimam"><FaTwitter /></a></li>
                                    <li><a hrefefef="https://www.linkedin.com/company/simam-digital-ltd/"><FaLinkedin /></a></li>
                                    <li><a hrefefef="https://www.instagram.com/simam.digital/"><FaInstagram /></a></li>
                                    <li><a hrefefef="https://www.pinterest.com/"><FaPinterestP /></a></li>
                                    <li><a hrefefef="https://vimeo.com/"><FaVimeoV /></a></li>
                                    <li><a hrefef="https://github.com/Simam-Digital-Ltd/"><FaGithub /></a></li>
                                    <li><a href="https://www.behance.net/junaidmalik21/"><FaBehance /></a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Offcanvas.Body>
        </Offcanvas>
    )
}

export default OffcanvasMenu;
