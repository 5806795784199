import React, {useRef, useState} from 'react';
import emailjs from '@emailjs/browser';
import Alert from 'react-bootstrap/Alert';

const Result = () => {
    return (
        <Alert variant="success" className="success-msg">
        Your Message has been successfully sent.
        </Alert>
    )
}

function FormThree() {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [message, setMessage] = useState('');

    const handleSubmit = (event) => {
        event.preventDefault();
        window.location.href = `mailto:support@simamdigital.com?subject=Free%20Quote%20from%20${name}&body=${message}%0D%0A%0D%0AContact%20Details:%0D%0AName:%20${name}%0D%0AEmail:%20${email}%0D%0APhone:%20${phone}`;
    }

    return (
        <form onSubmit={handleSubmit} className="axil-contact-form">
            <div className="form-group">
                <label>Name</label>
                <input type="text" className="form-control" name="user_name" placeholder="John Smith" required onChange={(e) => setName(e.target.value)} />
            </div>
            <div className="form-group">
                <label>Email</label>
                <input type="email" className="form-control" name="user_email" placeholder="example@mail.com" required onChange={(e) => setEmail(e.target.value)} />
            </div>
            <div className="form-group mb--40">
                <label>Phone</label>
                <input type="tel" className="form-control" name="contact_phone" placeholder="+123456789" required onChange={(e) => setPhone(e.target.value)} />
            </div>
            <div className="form-group mb--40">
                <label>Message</label>
                <textarea className="form-control" name="message" placeholder="Your Message" required onChange={(e) => setMessage(e.target.value)}></textarea>
            </div>
            <div className="form-group">
                <button type="submit" className="axil-btn btn-fill-primary btn-fluid btn-primary">Get Free Quote</button>
            </div>
        </form>
    );
}


export default FormThree;