import React from 'react'
import PropTypes from "prop-types";


const SEO = ( {title} ) => {
    return (
        <>
            <meta charSet="utf-8" />
            <title>Simam Digital | {title}</title>
            <meta name="description" content="Simam Digital is a digital agency that provides a wide range of for your digital needs, app development, spatial apps, ar ,vr, digital twins and many others." />
            <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
        </>
    )
}
SEO.propTypes = {
    title: PropTypes.string
};

export default SEO;